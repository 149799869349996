import React from 'react'
import soluptons from "../../../assets/img/techex-landing-page/we-provide-solutopns-bg.png"


import { Row, Col } from 'react-bootstrap'

const Mobileimagesection = () => {
    return (
        <Row className="top-margin">
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(    ${soluptons})` }} alt="mobile application development">
                    <div className="content">
                        <h3>Custom Mobile App Development</h3>
                        <hr />
                        <p>We specialize in crafting custom mobile applications tailored to your unique business.iOS, Android, or cross-platform, we can bring your vision to life.</p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>

                    <div className="content">
                        <h3>User-Centric Design and Development</h3>
                        <hr />
                        <p>
                            We prioritize user-centric design and development principles to create intuitive, enjoyable mobile apps that excel in usability, accessibility, and aesthetics..
                        </p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                    <div className="content">
                        <h3>Native and Cross-Platform Development</h3>
                        <hr />
                        <p>We deliver high-quality mobile apps, whether native or cross-platform, ensuring optimal performance or broader reach based on your preference</p>
                    </div>
                </a>
            </div>

            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                    <div className="content">
                        <h3>Integration and Deployment</h3>
                        <hr />
                        <p>We manage the full app development , ensuring seamless integration with backend systems and app stores for a hassle-free experience.</p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                    <div className="content card-image-mobile-service">
                        <h3>App Testing and Quality Assurance</h3>
                        <hr />
                        <p>Build a well-constructed website and web application  functionality & interactivity  that better serve all the Industry.</p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>

                    <div className="content card-image-mobile-service">
                        <h3>App Maintenance and Support
                        </h3>
                        <hr />
                        <p>We take your hassle time of maintaining any mobile  application and its functionality to better serve your customers. </p>
                    </div>
                </a>
            </div>

        </Row>
    )
}

export default Mobileimagesection