import React from 'react'
import sicon1 from "../assets/img/icon/sicon1.png"
import sicon2 from "../assets/img/icon/sicon2.png"
import sicon3 from "../assets/img/icon/sicon3.png"
import sicon4 from "../assets/img/icon/sicon4.png"

const Popularitserviceservicesection = () => {
  return (
    <div className='body-wrapper'>

<section class="services-wrapper service-1 section-padding section-bg">
        <div class="container">
            <div class="row mb-50">
                <div class="col-12 col-lg-12">
                    <div class="section-title text-white text-center">
                        <p>Popular IT services</p>
                        <h1>Our Professional Solutions <br/>
                            For IT Business</h1>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-xl-3 col-12">
                    <div class="single-service-item">
                        <div class="icon">
                            <img src={sicon1} alt="service"/>
                        </div>
                        <h4>Manage IT Services</h4>
                        <p>Sed perspicias unde omnis natus error volute</p>
                        <a href="/servicedetail">learn more <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-md-6 col-xl-3 col-12">
                    <div class="single-service-item">
                        <div class="icon">
                            <img src={sicon2} alt="service"/>
                        </div>
                        <h4>Product  Engineering</h4>
                        <p>Sed perspicias unde omnis natus error volute</p>
                        <a href="/servicedetail">learn more <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-md-6 col-xl-3 col-12">
                    <div class="single-service-item">
                        <div class="icon">
                            <img src={sicon3} alt="service"/>
                        </div>
                        <h4>Web Development</h4>
                        <p>Sed perspicias unde omnis natus error volute</p>
                        <a href="/servicedetail">learn more <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-md-6 col-xl-3 col-12">
                    <div class="single-service-item">
                        <div class="icon">
                            <img src={sicon4} alt="service"/>
                        </div>
                        <h4>Digital Solutions</h4>
                        <p>Sed perspicias unde omnis natus error volute</p>
                        <a href="/servicedetail">learn more <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section> 
    </div>
  )
}

export default Popularitserviceservicesection