import React from 'react'
import { HiDocument } from "react-icons/hi2";
import { BsFillShieldLockFill } from "react-icons/bs";

import { HiOutlineMagnifyingGlassPlus } from "react-icons/hi2";
import { MdAccountBalanceWallet } from "react-icons/md";
import { BsGraphUpArrow } from "react-icons/bs";
import { BsDatabaseDown } from "react-icons/bs";
import { GoWorkflow } from "react-icons/go";
import { IoSearch } from "react-icons/io5";

const   Productpagefirstsection = () => {
  return (
    <div className='body-wrapper' >
         <section class="service_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-6 col-md-6 offset-md-3 text-center">
                <div className="section-title mt-5">
                            <h1 data-aos="fade-up" data-aos-delay="100" >Presti Docs</h1>
                            <p className='' style={{fontWeight:'500'}}>We transform paper chaos to digital ease</p>
                        </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-4 col-sm-6 mt-40" data-aos="fade-up" data-aos-delay="100">
                    <div class="single_service_items text-center">
                        <div class="icon">
                        <HiDocument />
                        </div>
                        <h4 className='producth4'>Unified Document Repository</h4>
                        <p>Experience the convenience of a singular hub for all your documents. No more scattered files, no more time wasted in searches</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 mt-40" data-aos="fade-up" data-aos-delay="100">
                    <div class="single_service_items text-center">
                        <div class="icon">
                      <BsFillShieldLockFill />
                        </div>
                        <h4 className='producth4'>Robust Document Security</h4>
                        <p>Your documents are invaluable, and PrestiDocs treats them as such. Our system ensures top-notch security with robust access controls</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 mt-40" data-aos="fade-up" data-aos-delay="100">
                    <div class="single_service_items text-center">
                        <div class="icon">
                        <IoSearch />
                        </div>
                        <h4 className='producth4'>Swift Retrieval and Searchability</h4>
                        <p>Find what you need in seconds with advanced search functionalities. Boost productivity and efficiency in document retrieval</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 mt-40" data-aos="fade-up" data-aos-delay="100">
                    <div class="single_service_items text-center">
                        <div class="icon">
                        <MdAccountBalanceWallet />
                        </div>
                        <h4 className='producth4'>Accountability with Audit Trail:</h4>
                        <p>Rest easy knowing every document action is recorded. Our detailed audit trail ensures transparency and compliance</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 mt-40" data-aos="fade-up" data-aos-delay="100">
                    <div class="single_service_items text-center">
                        <div class="icon">
                        <BsGraphUpArrow />
                        </div>
                        <h4 className='producth4'>Scalability Without Limits:</h4>
                        <p>Grow your document management seamlessly. Presti Docs adapts to the evolving needs of your organization</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 mt-40" data-aos="fade-up" data-aos-delay="100">
                    <div class="single_service_items text-center">
                        <div class="icon">
                        <BsDatabaseDown />
                        </div>
                        <h4 className='producth4'>Effortless Data Migration</h4>
                        <p>Concerned about transitioning from paper to digital? PrestiDocs makes data migration smooth and hassle-free</p>
                    </div>
                </div>
                {/* <div class="col-lg-4 col-sm-6 mt-40" data-aos="fade-up" data-aos-delay="100">
                    <div class="single_service_items text-center">
                        <div class="icon">
                        <GoWorkflow />
                        </div>
                        <h4 className='producth4'>Customise Your Workflow</h4>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some </p>
                    </div>
                </div> */}
               
            </div>
        </div>
       
    </section>
    </div>
  )
}

export default Productpagefirstsection