import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import praathee1 from "../assets/img/1.webp";
import praathee2 from "../assets/img/2.webp";
// import praathee3 from "../assets/img/3_1_11zon.webp";
import praathee3 from "../assets/img/3_2 (1) (1)_batcheditor_fotor.webp";
// import praathee1 from "../assets/img/Frame218.png";
// import praathee2 from "../assets/img/Frame225.png";
// import praathee3 from "../assets/img/Cloudolutions.png";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { BsArrowLeftSquareFill } from "react-icons/bs";

function Homepagetopbanner() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="custom-carousel-container">
      {/* <div style={{ position: 'relative', marginTop: "-20px", height: '550px' }}> */}
      <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false}>
        <Carousel.Item>
          <img src={praathee1} alt="software development" />
          {/* <Link to="/contact" className="floating-btn">Learn More</Link> */}
        </Carousel.Item>
        <Carousel.Item>
          <img src={praathee2} alt="app development" />
          {/* <Link to="/contact" className="floating-btn">Learn More</Link> */}
        </Carousel.Item>
        <Carousel.Item>
          <img src={praathee3} alt="block chain technology" />
          {/* <Link to="/contact" className="floating-btn">Learn More</Link> */}
        </Carousel.Item>
      </Carousel>
      <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
        <BsArrowLeftSquareFill
          style={{ color: '#ffdcb7', fontSize: '30px', marginRight: '10px', cursor: 'pointer' }}
          onClick={() => setIndex(index === 0 ? 2 : index - 1)} // Adjust the index accordingly
        />
        <BsFillArrowRightSquareFill
          style={{ color: '#0a6aa0', fontSize: '30px', cursor: 'pointer' }}
          onClick={() => setIndex(index === 2 ? 0 : index + 1)} // Adjust the index accordingly
        />
      </div>
    </div>
    //     <div className="custom-carousel-container">
    //   <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false} style={{ maxWidth: '100%' }}>
    //     <Carousel.Item>
    //       <img src={praathee1} alt="software development" className="carousel-image" />
    //       {/* <Link to="/contact" className="floating-btn">Learn More</Link> */}
    //     </Carousel.Item>
    //     <Carousel.Item>
    //       <img src={praathee2} alt="app development" className="carousel-image" />
    //       {/* <Link to="/contact" className="floating-btn">Learn More</Link> */}
    //     </Carousel.Item>
    //     <Carousel.Item>
    //       <img src={praathee3} alt="block chain technology" className="carousel-image" />
    //       {/* <Link to="/contact" className="floating-btn">Learn More</Link> */}
    //     </Carousel.Item>
    //   </Carousel>
    //   <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
    //     <BsArrowLeftSquareFill
    //       style={{ color: '#ffdcb7', fontSize: '30px', marginRight: '10px', cursor: 'pointer' }}
    //       onClick={() => setIndex(index === 0 ? 2 : index - 1)} // Adjust the index accordingly
    //     />
    //     <BsFillArrowRightSquareFill
    //       style={{ color: '#0a6aa0', fontSize: '30px', cursor: 'pointer' }}
    //       onClick={() => setIndex(index === 2 ? 0 : index + 1)} // Adjust the index accordingly
    //     />
    //   </div>
    // </div>

  );
}

export default Homepagetopbanner;
