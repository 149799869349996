import React from 'react'
import Topnavbar from '../common components/Topnavbar';
import Footer from '../common components/Footer';
import Newsfeedtopbanner from './Newsfeedtopbanner';
import Newsfeedfirstsection from "./Newsfeedfirstsection";

const Newsfeedwholepage = () => {
  return (
    <div>
        <Topnavbar/>
      <Newsfeedtopbanner/>
      <Newsfeedfirstsection/>
      <Footer/> 
    </div>
  )
}

export default Newsfeedwholepage