import React from 'react';
import aboutcompany from "../assets/img/designing-an.png";

const Aboutcompany = () => {
  return (
    <div className='body-wrapper container'>
      <section className="about-wrapper techex-landing-page">
      {/* <section className="about-wrapper techex-landing-page"> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-img">
                <img src={aboutcompany} className="img-fluid" alt="Designing An Exceptional Global Experience"/>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="section-title">
                <h1 data-aos="fade-left" data-aos-delay="100">Designing an exceptional global experience</h1>
                <div data-aos="fade-left" data-aos-delay="150">
                  <p>We are well versed in developing tailor-made applications using Microsoft technologies and open source. We understand that every business is unique and has its own set of challenges and goals.</p>
                </div>

                <div className="rate-content-grid d-flex justify-content-between">
                  <div className="single-rate-item" data-aos="fade-up">
                    <h3>7+</h3>
                    <p>Years Of Establishment</p>
                  </div>
                  <div className="single-rate-item" data-aos="fade-up" data-aos-delay="100">
                    <h3>100+</h3>
                    <p>Successful Projects</p>
                  </div>
                  <div className="single-rate-item" data-aos="fade-up" data-aos-delay="200">
                    <h3>24/7</h3>
                    <p>Support</p>
                  </div>
                </div>

                <a href="/about" className="theme-btn black" data-aos="fade-up" data-aos-delay="200">Know us more <i className="icon-arrow-right-1"></i></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Aboutcompany;
