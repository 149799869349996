import React from 'react';
import logo from "../assets/img/praatheelogo.png";
import logo2 from "../assets/img/praatheelogo.png";
import { IoMenu } from "react-icons/io5";
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useLocation } from 'react-router-dom';
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { FaLinkedin, FaFacebookSquare } from "react-icons/fa";
import { TbMailFilled, TbPhoneFilled } from "react-icons/tb";
import { FaSquareInstagram } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";




const Topnavbar = () => {
    const [show, setShow] = useState(false);
    const location = useLocation();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };




    return (
        <body className='body-wrapper'>
            <header className="header-wrap header-5 sticky-top">
                <div className="top-header d-none d-md-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="header-cta">
                                    <ul>
                                        <li><a href="#" style={{ fontSize: "12px" }}><FaLocationDot style={{ fontSize: "16px" }} /> Innovspace Business Center, Coimbatore-641014</a></li>
                                        <li><a href="mailto:support@praathee.com" style={{ fontSize: "12px" }}><TbMailFilled style={{ fontSize: "16px" }} /> support@praathee.com</a></li>
                                        <li><a href="tel:+917092484157" style={{ fontSize: "12px" }}><TbPhoneFilled style={{ fontSize: "16px" }} /> +91 7092484157</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="header-right-cta d-flex justify-content-end">
                                    <div className="social-profile">
                                        <a href="https://www.facebook.com/Praathee?mibextid=YMEMSu"><FaFacebookSquare style={{ fontSize: '24px' }} /></a>
                                        <a href="https://www.linkedin.com/company/praathee-media-private-limited/mycompany/?viewAsMember=true"><FaLinkedin style={{ fontSize: '24px' }} /></a>
                                        <a href="https://www.instagram.com/praathee_media?igsh=cXk3azRjOTd4b3ln&utm_source=qr"><FaSquareInstagram style={{ fontSize: '24px' }} /></a>
                                        <a href="https://www.youtube.com/@PraatheeMedia"><IoLogoYoutube style={{ fontSize: '27 px' }} /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-header-wraper">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-between" style={{ height: '70px' }}>
                            <div className="header-logo">
                                <div className="logo">
                                    <a href="/">
                                        <img src={logo} alt="logo" className="img-fluid" style={{ maxWidth: '100%' }} />
                                    </a>
                                </div>
                                <div className="logo-2">
                                    <a href="/">
                                        <img src={logo2} alt="logo" />
                                    </a>
                                </div>
                            </div>
                            <div className="header-menu d-none d-xl-block ">
                                <div className="main-menu my-auto">
                                    <ul className='my-auto'>
                                        <li>
                                            <a href="/" className="nav-link" style={{ textDecoration: 'none', color: location.pathname === '/' ? '#086ad7' : '' }}>Home<i className="fas fa-angle-down text-white"></i></a>
                                        </li>
                                        <li>
                                            <a href="/about" className="nav-link" style={{ textDecoration: 'none', color: location.pathname === '/about' ? '#086ad7' : '' }}>About<i className="fas fa-angle-down text-white"></i></a>
                                        </li>
                                        <li>
                                            <a href="#" className="nav-link" style={{
                                                textDecoration: 'none',
                                                color: location.pathname.startsWith('/services') || location.pathname.startsWith('/web-app-development-service') || location.pathname.startsWith('/mobile-app-development-service') || location.pathname.startsWith('/erp-development') || location.pathname.startsWith('/blockchain-development-service') || location.pathname.startsWith('/cloud-app-development-service') || location.pathname.startsWith('/customservice') || location.pathname.startsWith('/software-maintenance-service') || location.pathname.startsWith('/it-staffing-service') ? '#086ad7' : ''
                                            }}>
                                                Services
                                                <i className="fas fa-angle-down hidden"></i>
                                            </a>
                                            <ul className="service-menu">
                                                <li><a href="/web-app-development-service" className="nav-link">Web Application Development</a></li>
                                                <li><a href="/mobile-app-development-service" className="nav-link">Mobile Application Development</a></li>
                                                <li><a href="/erp-development" className="nav-link">Enterprise Software Development</a></li>
                                                <li><a href="/blockchain-development-service" className="nav-link">Block Chain Development</a></li>
                                                <li><a href="/cloud-app-development-service" className="nav-link">Cloud Application Development</a></li>
                                                <li><a href="/software-maintenance-service" className="nav-link">Software Maintenance & Support</a></li>
                                                <li><a href="/it-staffing-service" className="nav-link">IT Staffing & Support</a></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#" className="nav-link" style={{ textDecoration: 'none', color: location.pathname.startsWith('/products') || location.pathname.startsWith('/prestidocs') ? '#086ad7' : '' }}>Products
                                                <i className="fas fa-angle-down "></i>
                                            </a>
                                            <ul className="sub-menu">
                                                <li><a href="/prestidocs" className="nav-link">Presti Docs</a></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="/careerpage" className="nav-link" style={{ textDecoration: 'none', color: location.pathname === '/careerpage' ? '#086ad7' : '' }}>Career<i className="fas fa-angle-down text-white"></i></a>
                                        </li>
                                        <li>
                                            <a href="/contact" className="nav-link" style={{ textDecoration: 'none', color: location.pathname === '/contact' ? '#086ad7' : '' }}>Contact<i className="fas fa-angle-down text-white"></i></a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className="header-right d-flex align-items-center">
                                <div className="header-btn-cta">
                                    <a href="/contact" className="theme-btn">Get in Touch<i className="icon-arrow-right-1"></i></a>
                                </div>
                                <div className="mobile-nav-bar d-block ml-3 ml-sm-4 d-xl-none">
                                    <div className="mobile-nav-wrap">
                                        <div id="hamburger">
                                            <IoMenu className='' style={{ cursor: 'pointer' }} onClick={handleShow} />
                                        </div>
                                        <Offcanvas className="mobile-nav" show={show} onHide={handleClose}>
                                            <Offcanvas.Header closeButton>
                                                <div className="logo d-flex justify-content-between">
                                                    <a href="/">
                                                        <img src={logo} alt="logo" className="img-fluid" style={{ maxWidth: '100%' }} />
                                                    </a>
                                                </div>
                                                <div style={{ borderBottom: '2px solid #000' }}></div>
                                            </Offcanvas.Header>


                                            <Offcanvas.Body>
                                   <nav className="sidebar-nav">
    <ul className="metismenu" id="mobile-menu">
        <li><a className="has-arrow" href="/">Home</a></li>
        <li><a href="/about">About</a></li>
        <li><a href="/prestidocs">Products</a></li>
        <li className="dropdown" onMouseLeave={() => setIsSubMenuOpen(false)}>
            <a href="#" className="dropdown-toggle" onClick={(e) => { e.preventDefault(); toggleSubMenu() }}>
                Services
            </a>
            <ul className={isSubMenuOpen ? "sub-menu open" : "sub-menu"}>
                <li><a href="/web-app-development-service">Web Application Development</a></li>
                <li><a href="/mobile-app-development-service">Mobile Application Development</a></li>
                <li><a href="/erp-development">Enterprise Software Development</a></li>
                <li><a href="/blockchain-development-service">Blockchain Development</a></li>
                <li><a href="/cloud-app-development-service">Cloud Application Development</a></li>
                <li><a href="/software-maintenance-service">Software Maintenance & Support</a></li>
                <li><a href="/it-staffing-service">IT Staffing & Support</a></li>
            </ul>
        </li>
        <li><a href="/careerpage">Career</a></li>
        <li><a href="/contact">Contact</a></li>
    </ul>
</nav>

                                                <div className="action-bar">
                                                    <a href="#" className="d-flex align-items-center">
                                                        <FaLocationDot />   Innovspace Business Center, <br /> Coimbatore-641014
                                                    </a>
                                                    <a href="mailto:support@praathee.com">
                                                    <TbMailFilled/>support@praathee.com
                                                    </a>
                                                    <a href="tel:+917092484157">
                                                        <TbPhoneFilled/>+91 7092484157
                                                    </a>
                                                </div>

                                            </Offcanvas.Body>
                                        </Offcanvas>

                                    </div>
                                    <div className="overlay"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="header-gutter"></div>
        </body>
    );
};

export default Topnavbar;
