import React from 'react'
import Topnavbar from '../common components/Topnavbar'
import Howcanhelpyou from './Howcanhelpyou'
import Listofclients from './Listofclients'
import Aboutcompany from './Aboutcompany'
// import Whatwedo from './Whatwedo'
// import Meetourexpertteam from './Meetourexpertteam'
// import Ourcompletedprojects from './Ourcompletedprojects'
// import Clienttestimonials from './Clienttestimonials'
// import Articlesandtipes from './Articlesandtipes'
import Footer from '../common components/Footer'
import Homepagetopbanner from './Homepagetopbanner'
// import HomepageContent from './contenthomepage'
// import Latestblog from './Latestblog'
// import Productpagefirstsection from '../Products/Productpagefirstsection'
import { Helmet } from 'react-helmet';

const Homepage = () => {
  return (
    <div>
      <Helmet>
        <title>Best Software Development Company in Coimbatore | Praathee</title>
        <meta name="description" content="Praathee is a Leading software development company in Coimbatore, providing innovative solutions tailored to meet your specific business needs." />
        <meta property="og:title" content="Top Software Development Company in Coimbatore for Innovative Solutions" />
        <meta property="og:description" content="our digital journey begins with us: 7 years of software mastery in Coimbatore, offering mobile app, web, cloud, and blockchain solutions tailored for success." />
        <meta name="keywords" content="software developmet, mobile app development , web app development, coimbatore, software developer, blockchain devlopment, cloud app development, software company, it company" />
      </Helmet>
      <Topnavbar />
      <Homepagetopbanner />
      {/* <HomepageContent/> */}
      <Howcanhelpyou />
      <Listofclients />
      <Aboutcompany />
      {/* <Latestblog/> */}
      {/* <Whatwedo/> */}
      {/* <Meetourexpertteam/>
        <Ourcompletedprojects/> */}
      {/* <Clienttestimonials/> */}
      {/* <Articlesandtipes/> */}
      <Footer />
    </div>
  )
}

export default Homepage