

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import paraimage from "../../../assets/img/New_Cloud-1.webp";
import { LuDot } from "react-icons/lu";


const Enterprisefirstsection = () => {
  return (
    <div className='web-text'>
      <Row className="justify-content-center">
        <Col md={12} className="mb-4">
          <p style={{ textAlign: 'justify', lineHeight: '2.2' }}>

            We are a leading company specializing in crafting customized cloud applications for businesses of all sizes, boasting a rich seven-year legacy. Our expertise lies in cutting-edge cloud application development services. By harnessing the capabilities of cloud technology, we design innovative and scalable software solutions to propel your business into the digital age.
          </p>
        </Col>
        <Col md={6} className='web_page_img_div'>
          <img
            src={paraimage}
            alt='Cloud Application Service'
            className="custom-image-webservice"
            style={{ borderRadius: '10px' }}
          />
        </Col>
        <Col md={6} className='d-flex align-items-center'>
          <ul className='icon-list'>
            <li className="icon-list-item">
              <LuDot className="icon" />
              <span style={{ marginLeft: '10px' }}>Utilise the latest in cloud technology for scalable, flexible, and secure applications tailored to your unique business needs.</span>
            </li>
            <li className="icon-list-item">
              <LuDot className="icon" />
              <span style={{ marginLeft: '10px' }}>Take advantage of our expertise across major cloud platforms, ensuring a customised approach that aligns with your strategic goals.</span>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default Enterprisefirstsection;
