import React from 'react'
import Topnavbar from "../common components/Topnavbar";
import Footer from "../common components/Footer";
import Servicesectiontopbanner from "./Servicesectiontopbanner";
import Howcanhelpyouservicesection from "./Howcanhelpyouservicesection";
import Popularitserviceservicesection from "./Popularitserviceservicesection";
import Countsservicesection from "./Countsservicesection";
import Proffesionalsolutionsservicesection from "./Proffesionalsolutionsservicesection";
import Listofclientsservicesection from "./Listofclientsservicesection";

const Serviceswholepage = () => {
  return (
    <div>
      <Topnavbar/>
      <Servicesectiontopbanner/>
      <Howcanhelpyouservicesection/>
      <Popularitserviceservicesection/>
      <Countsservicesection/>
      <Proffesionalsolutionsservicesection/>
      <Listofclientsservicesection/>
      <Footer/> 
    </div>
  )
}

export default Serviceswholepage
