import React from 'react'
import authorimg from "../../assets/img/blog/author_img.jpg"
import blogb1 from "../../assets/img/blog/b1.jpg"
import blogb2 from "../../assets/img/blog/b2.jpg"
import blogb3 from "../../assets/img/blog/b3.jpg"
import blogb4 from "../../assets/img/blog/b4.jpg"
import blogpp1 from "../../assets/img/blog/pp1.jpg"
import blogpp2 from "../../assets/img/blog/pp2.jpg"
import blogpp3 from "../../assets/img/blog/pp3.jpg"
import blogpp4 from "../../assets/img/blog/pp4.jpg"
import ip1 from "../../assets/img/blog/ip1.jpg"
import ip2 from "../../assets/img/blog/ip2.jpg"
import ip3 from "../../assets/img/blog/ip3.jpg"
import ip4 from "../../assets/img/blog/ip4.jpg"
import ip5 from "../../assets/img/blog/ip5.jpg"
import ip7 from "../../assets/img/blog/ip7.jpg"
import blogad from "../../assets/img/blog_bg.png"


const Newsdetail = () => {
  return (
    <div className='body-wrapper'>
        <section class="blog-wrapper news-wrapper section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-8">
                    <div class="blog-post-details border-wrap">
                        <div class="single-blog-post post-details">                            
                            <div class="post-content">
                                <div class="post-cat">
                                    <a href="#">business</a>
                                    <a href="#">it</a>
                                </div>
                                <h2>Beyond Tools How Building A Design System 
                                    Can Improve How You Work</h2>
                                <div class="post-meta">
                                    <span><i class="fal fa-eye"></i>232 Views</span>
                                    <span><i class="fal fa-comments"></i>35 Comments</span>
                                    <span><i class="fal fa-calendar-alt"></i>24th March 2019</span>
                                </div>
                                <p>Flyingfish Kafue pike cow shark California smoothtongue golden loach temperate ocean-bass gulper eel. Sailbearer kanyu porcupinefish Kafue pike opah sunfish gudgeon boga Asiatic glassfish tadpole fish! Alewife, poacher airbreathing catfish; zebra tilapia northern pearleye naked-back knifefish pupfish dojo loach,</p>
                                <p>Asian carps sailback scorpionfish; dragon goby lemon sole triplefin blenny hog sucker. Smelt sleeper shovelnose sturgeon merluccid hake cow shark herring smelt trout-perch barbeled houndshark. Shell-ear Asian carps blackfish Port Jackson shark Atlantic saury. Sacramento blackfish pricklefish, Atlantic cod</p>
                                <p>Northern anchovy–bass yellowtail barracuda zander yellowfin grouper gurnard skipjack tuna shark burrowing goby eulachon wobbegong. Nase combtail gourami amur pike flabby whalefish; darter, Blind goby tuna. Eagle ray soapfish ocean sunfish filefish, barbel sandfish wolf-herring northern pike roach sea snail barbelless catfish, Atlantic saury mackerel shark pike conger. Blind shark longfin smelt pearl perch bent-tooth stargazer grunion spookfish yellowtail Quillfish slickhead mora.  snake worm mackerel sockeye salmon banjo catfish toadfish sauger four-eyed fish</p>
                                <img src="assets/img/blog/p1.jpg" alt=""/>
                                <h2>A cleansing hot shower or bath</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidid unt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in repreh enderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                <blockquote>
                                    Viral dreamcatcher keytar typewriter, aest hetic offal umami. Aesthetic polaroid pug pitchfork post-ironic.
                                </blockquote>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidid unt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute.</p>

                                <ul>
                                    <li>We track every dollar</li>
                                    <li>We’re an open book</li>
                                    <li>100% goes to the field</li>
                                    <li>Received the highest grades</li>
                                </ul>                                
                                <h3>Lorem ipsum dolor sit amet, consectetur adipisicing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus</p>
                                <img class="alignleft" src="assets/img/blog/post-inner.jpg" alt=""/>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p>                                                                                                
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus.</p>                                                            
                            </div>
                        </div>
                        <div class="row tag-share-wrap">
                            <div class="col-lg-6 col-12">
                                <h4>Releted Tags</h4>
                                <div class="tagcloud">                                   
                                    <a href="/newsdetail">Technology</a>
                                    <a href="/newsdetail">it</a>
                                    <a href="/newsdetail">business</a>
                                </div>
                            </div>
                            <div class="col-lg-6 col-12 text-lg-right">
                                <h4>Social Share</h4>
                                <div class="social-share">
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                    <a href="#"><i class="fab fa-linkedin-in"></i></a>                                    
                                </div>
                            </div>
                            <hr/>
                        </div>

                        <div class="related-post-wrap">
                            <h3>Releted Post</h3>

                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <div class="single-related-post">
                                        <div class="featured-thumb bg-cover" style={{backgroundImage:`url(${blogb1})`}}></div>
                                        <div class="post-content">
                                            <div class="post-date">
                                                <span><i class="fa fa-calendar"></i>27th March 2021</span>
                                            </div>
                                            <h4><a href="/newsdetail">How To Provide Fresh Water In Nigeria</a></h4>
                                            <p>Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12">
                                    <div class="single-related-post">
                                        <div class="featured-thumb bg-cover" style={{backgroundImage:`url(${blogb3})`}}></div>
                                        <div class="post-content">
                                            <div class="post-date">
                                                <span><i class="fal fa-calendar-alt"></i>24th July 2020</span>
                                            </div>
                                            <h4><a href="/newsdetail">How Does Malnutrition Affect Children.</a></h4>
                                            <p>Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- comments section wrap start --> */}
                        <div class="comments-section-wrap pt-40">
                            <div class="comments-heading">
                                <h3>03 Comments</h3>
                            </div>
                            <ul class="comments-item-list">
                                <li class="single-comment-item">
                                    <div class="author-img">
                                        <img src="assets/img/blog/author_img.jpg" alt=""/>
                                    </div>
                                    <div class="author-info-comment">
                                        <div class="info">
                                            <h5><a href="#">Rosalina Kelian</a></h5>
                                            <span>19th May 2018</span>
                                            <a href="#" class="theme-btn minimal-btn"><i class="fal fa-reply"></i>Reply</a>
                                        </div>
                                        <div class="comment-text">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Ut enim ad minim veniam, quis nostrud  laboris nisi ut aliquip ex ea commodo consequat.</p>
                                        </div>
                                    </div>
                                </li>
                                <li class="single-comment-item">
                                    <div class="author-img">
                                        <img src="assets/img/blog/author2.jpg" alt=""/>
                                    </div>
                                    <div class="author-info-comment">
                                        <div class="info">
                                            <h5><a href="#">Arista Williamson</a></h5>
                                            <span>21th Feb 2020</span>
                                            <a href="#" class="theme-btn minimal-btn"><i class="fal fa-reply"></i>Reply</a>
                                        </div>
                                        <div class="comment-text">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco nisi ut aliquip ex ea commodo consequat.</p>
                                        </div>
                                    </div>

                                    <ul class="replay-comment">
                                        <li class="single-comment-item">
                                            <div class="author-img">
                                                <img src="assets/img/blog/author3.jpg" alt=""/>
                                            </div>
                                            <div class="author-info-comment">
                                                <div class="info">
                                                    <h5><a href="#">Salman Ahmed</a></h5>
                                                    <span>29th Jan 2021</span>
                                                    <a href="#" class="theme-btn minimal-btn"><i class="fal fa-reply"></i>Reply</a>
                                                </div>
                                                <div class="comment-text">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam..</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div class="comment-form-wrap mt-40">
                            <h3>Post Comment</h3>

                            <form action="#" class="comment-form">
                                <div class="single-form-input">
                                    <textarea placeholder="Type your comments...."></textarea>
                                </div>
                                <div class="single-form-input">
                                    <input type="text" placeholder="Type your name...."/>
                                </div>
                                <div class="single-form-input">
                                    <input type="email" placeholder="Type your email...."/>
                                </div>
                                <div class="single-form-input">
                                    <input type="text" placeholder="Type your website...."/>
                                </div>
                                <button class="submit-btn" type="submit"><i class="fal fa-comments"></i>Post Comment</button>
                            </form>
                        </div>
                        
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="main-sidebar">
                        <div class="single-sidebar-widget author-box-widegts">
                            <div class="wid-title">
                                <h3>About Me</h3>
                            </div>
                            <div class="author-info text-center">
                                <div class="author-img" style={{backgroundImage:`url(${authorimg})`}}></div>
                                <h4>Rosalina D. Willaimson</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                <div class="social-profile">
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                    <a href="#"><i class="fab fa-linkedin-in"></i></a>
                                    <a href="#"><i class="fab fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="single-sidebar-widget">
                            <div class="wid-title">
                                <h3>Search Objects</h3>
                            </div>
                            <div class="search_widget">
                                <form action="#">
                                    <input type="text" placeholder="Search your keyword..."/>
                                    <button type="submit"><i class="fal fa-search"></i></button>
                                </form>
                            </div>
                        </div>
                        <div class="single-sidebar-widget">
                            <div class="wid-title">
                                <h3>Popular Feeds</h3>
                            </div>
                            <div class="popular-posts">
                                <div class="single-post-item">
                                    <div class="thumb bg-cover" style={{backgroundImage:`url(${blogpp1})`}}></div>
                                    <div class="post-content">
                                        <h5><a href="/newsdetail">Lorem ipsum dolor sit cing elit, sed do.</a></h5>
                                        <div class="post-date">
                                            <i class="far fa-calendar-alt"></i>24th March 2019
                                        </div>
                                    </div>
                                </div>
                                <div class="single-post-item">
                                    <div class="thumb bg-cover" style={{backgroundImage:`url(${blogpp2})`}}></div>
                                    <div class="post-content">
                                        <h5><a href="/newsdetail">Lorem ipsum dolor sit cing elit, sed do.</a></h5>
                                        <div class="post-date">
                                            <i class="far fa-calendar-alt"></i>25th March 2019
                                        </div>
                                    </div>
                                </div>
                                <div class="single-post-item">
                                    <div class="thumb bg-cover" style={{backgroundImage:`url(${blogpp3})`}}></div>
                                    <div class="post-content">
                                        <h5><a href="/newsdetail">Lorem ipsum dolor sit cing elit, sed do.</a></h5>
                                        <div class="post-date">
                                            <i class="far fa-calendar-alt"></i>26th March 2019
                                        </div>
                                    </div>
                                </div>
                                <div class="single-post-item">
                                    <div class="thumb bg-cover" style={{backgroundImage:`url(${blogpp4})`}}></div>
                                    <div class="post-content">
                                        <h5><a href="/newsdetail">Lorem ipsum dolor sit cing elit, sed do.</a></h5>
                                        <div class="post-date">
                                            <i class="fa fa-calendar"></i>29th March 2019
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="single-sidebar-widget">
                            <div class="wid-title">
                                <h3>Categories</h3>
                            </div>
                            <div class="widget_categories">
                                <ul>
                                    <li><a href="/News">Consultant <span>23</span></a></li>
                                    <li><a href="/News">Help <span>24</span></a></li>
                                    <li><a href="/News">Education <span>11</span></a></li>
                                    <li><a href="/News">Technology <span>05</span></a></li>
                                    <li><a href="/News">business <span>06</span></a></li>
                                    <li><a href="/News">Events <span>10</span></a></li>    
                                </ul>                                
                            </div>
                        </div>
                        <div class="single-sidebar-widget">
                            <div class="wid-title">
                                <h3>Never Miss News</h3>
                            </div>
                            <div class="social-link">
                                <a href="#"><i class="fab fa-facebook-f"></i></a>
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                                <a href="#"><i class="fab fa-linkedin-in"></i></a>
                                <a href="#"><i class="fab fa-youtube"></i></a>
                            </div>
                        </div>
                        <div class="single-sidebar-widget instagram-widget">
                            <div class="wid-title">
                                <h3>Instagram Feeds</h3>
                            </div>
                            <div class="instagram-gallery">
                                <a href={ip1} class="single-photo-item bg-cover popup-gallery" style={{backgroundImage:`url(${ip1})`}}>                                    
                                </a>
                                <a href={ip2} class="single-photo-item bg-cover popup-gallery" style={{backgroundImage:`url(${ip2})`}}>                                    
                                </a>
                                <a href={ip3} class="single-photo-item bg-cover popup-gallery" style={{backgroundImage:`url(${ip3})`}}>                                    
                                </a>
                                <a href={ip4} class="single-photo-item bg-cover popup-gallery" style={{backgroundImage:`url(${ip4})`}}>                                    
                                </a>
                                <a href={ip5} class="single-photo-item bg-cover popup-gallery" style={{backgroundImage:`url(${ip5})`}}>                                    
                                </a>
                                <a href={ip7} class="single-photo-item bg-cover popup-gallery" style={{backgroundImage:`url(${ip7})`}}>                                    
                                </a>
                            </div>
                        </div>
                        <div class="single-sidebar-widget">
                            <div class="wid-title">
                                <h3>Popular Tags</h3>
                            </div>
                            <div class="tagcloud">
                                <a href="#">event</a>     
                                <a href="#">help</a>
                                <a href="#">ux</a>
                                <a href="#">food</a>                                
                                <a href="#">ui</a>
                                <a href="#">water</a>
                                <a href="#">charity</a>
                                <a href="#">donate</a>
                            </div>
                        </div>
                        <div class="sidebar-ad-widget">
                            <div class="ad-wraper">
                                <a href="#" target="_blank">
                                    <img src={blogad} alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    </div>
  )
}

export default Newsdetail