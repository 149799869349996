import React from 'react';
import { Row, Col } from 'react-bootstrap';
// import backgimage from "../../../assets/img/backimage.jpg";
import paraimage from "../../../assets/img/new-web-3.webp";
// import { ImPointRight } from "react-icons/im";
import { LuDot } from "react-icons/lu";
// import { TbSquareRoundedNumber3Filled } from "react-icons/tb";
// import { TbSquareRoundedNumber4Filled } from "react-icons/tb";

const Webfirstsection = () => {
  return (
    <div className='web-text'>
      <Row className="justify-content-center">
        <Col md={12} className="mb-4">
          <p style={{ textAlign: 'justify', lineHeight: '2.2' }}>

             We are a leading web application development company that specializes in creating custom web applications for businesses of all sizes. With over [7+] years of experience, we delivering high-quality, scalable, and user-friendly web applications that help our clients achieve their business goals.
          </p>
        </Col>
        <Col md={6} className='web_page_img_div'>
            <img
              src={paraimage}
              alt='web application'
              className="custom-image-webservice"
              style={{ borderRadius: '10px' }}
            />
        </Col>
        <Col md={6} className='d-flex align-items-center'>
          <ul className='icon-list'>
            <li className="icon-list-item">
              {/* <LuDot className="icon" /> */}
              {/* <span style={{ marginLeft: '10px' , height:'70%' }}>We are a leading web application development company that specializes in creating custom web applications for businesses of all sizes. With over [7+] years of experience, we delivering high-quality, scalable, and user-friendly web applications that help our clients achieve their business goals.
</span> */}
            </li>
            <li className="icon-list-item">
              <LuDot className="icon" />
              <span style={{ marginLeft: '10px' }}>We create digital excellence based on your requirements, and our customised solutions transform the online experience.</span>
            </li>
            <li className="icon-list-item">
              <LuDot className="icon" />
              <span style={{ marginLeft: '10px' }}>Ensuring excellent web application performance through reliable support, timely updates, and proactive monitoring</span>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default Webfirstsection;

