import React from 'react'
import pagebanner from "../assets/img/page-banner.jpg"


const Newsfeedtopbanner = () => {
  return (
    <div className='body-wrapper'>
         <section class="page-banner-wrap bg-cover" style={{backgroundImage:`url(${pagebanner})`}}>
        <div class="banner-text">techex</div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 col-12">
                    <div class="page-heading text-white">
                        <div class="page-title">
                            <h1>News Feed</h1>
                        </div>
                    </div>

                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a href="/">Home</a></li>
                          <li class="breadcrumb-item active" aria-current="page">blog</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default Newsfeedtopbanner