import actions from './action';
import { takeEvery, all, call, put, select } from 'redux-saga/effects'
import axios from 'axios'
import { API_URL} from '../../utils/constant'

const BookDemoListSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_A_DEMO, DemoList),
        yield takeEvery(actions.GET_A_QUOTE, QuoteList),
        yield takeEvery(actions.GET_CAREER_FORM,CareerList),
    ]);

}

const DemoList = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/book_demo`,
           
                {
                    name: payload.data.name,
                    company_name: payload.data.company_name,
                    email: payload.data.email,
                    description: payload.data.description,
                    phone_number: payload.data.phone_number,
                    country: payload.data.country,
                    preferred_date:payload.data.preferred_date,
                    preferred_time:payload.data.preferred_time
                }
            )
        );

        if (result.data.statuscode === 200) {
            payload.navigation('/')
        } else {
        }

    } catch (error) {
        console.log("Error in catch", error);
    }
}
//*********************************QuoteList******************************/
const QuoteList = function* (data) {
    const { payload } = data;
    try {
        // Construct FormData object
        const formData = new FormData();
        formData.append('name', payload.data.name);
        formData.append('company_name', payload.data.company_name);
        formData.append('email', payload.data.email);
        formData.append('description', payload.data.description);
        formData.append('phone_number', payload.data.phone_number);
        formData.append('country', payload.data.country);

        // Append file data if available
        if (payload.data?.resume) {
            formData.append('resume', payload?.data?.resume);
        }   

        // Make POST request using axios
        const result = yield call(() =>
            axios.post(`${API_URL}/get_quote`, formData)
        );

        if (result.data.statuscode === 200) {
            payload.navigation('/');
        } else {
        }

    } catch (error) {
        console.log("Error in catch", error);
    }
};

//--------------------------------carerrList-------------------------------//

// const CareerList = function* (data) {
//     const { payload } = data;
//     try {
//         // Construct FormData object
//         const formData = new FormData();
//         formData.append('name', payload.data.name);
//         formData.append('company_name', payload.data.company_name);
//         formData.append('email', payload.data.email);
//         formData.append('current_location', payload.data.current_location);
//         formData.append('native_location', payload.data.native_location);
//         formData.append('position_for_applied', payload.data.position_for_applied);
//         formData.append('previous_current_ctc', payload.data.previous_current_ctc);
//         formData.append('expected_ctc', payload.data.expected_ctc);
//         formData.append('join_immediately', payload.data.join_immediately);
//         formData.append('notice_period', payload.data.notice_period);
//         formData.append('phonenumber', payload.data.phonenumber);
//         // formData.append('country', payload.data.country);

//         // Append file data if available
//         if (payload.data?.resume) {
//             formData.append('resume', payload?.data?.resume);
//         }   

//         // Make POST request using axios
//         const result = yield call(() =>
//             axios.post(`${API_URL}/career`, formData)
//         );

//         if (result.data.statuscode === 200) {
//             payload.navigation('/');
//         } else {
//         }

//     } catch (error) {
//         console.log("Error in catch", error);
//     }
// };

const CareerList = function* (data) {
    const { payload } = data;
    try {
        // Construct FormData object
        const formData = new FormData();
        formData.append('name', payload.data.name);
        formData.append('year_of_experience', payload.data.year_of_experience);
        formData.append('email', payload.data.email);
        formData.append('current_location', payload.data.current_location);
        formData.append('native_location', payload.data.native_location);
        formData.append('position_for_applied', payload.data.position_for_applied);
        formData.append('previous_current_ctc', payload.data.previous_current_ctc);
        formData.append('expected_ctc', payload.data.expected_ctc);
        formData.append('join_immediately', payload.data.join_immediately);
        formData.append('notice_period', payload.data.notice_period);
        formData.append('phonenumber', payload.data.phonenumber);

        // Append file data if available
        if (payload.data?.photo) {
            formData.append('photo', payload.data.photo);
        }

        if (payload.data?.resume) {
            formData.append('resume', payload.data.resume);
        }

        // Make POST request using axios
        const result = yield call(() =>
            axios.post(`${API_URL}/career`, formData)
        );

        if (result.data.statusCode === 200) {
            payload.navigation('/');
        } else {
            // Handle other status codes or errors
        }

    } catch (error) {
        console.log("Error in catch", error);
    }
};


export default BookDemoListSaga;
