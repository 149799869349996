import React from 'react'
import Topnavbar from '../../../common components/Topnavbar'
import Customtopbanner from './Customtopbanner'
import Customfirstsection from './Customfirstsection'
import Customimagesection from './Customimagesection'
import Footer from '../../../common components/Footer'

const Customwholepage = () => {
  return (
    <>
    <Topnavbar/>
    <Customtopbanner/>
    <Customfirstsection/>
    <Customimagesection/>
    <Footer/>
    </>
  )
}

export default Customwholepage