import React from 'react'
import Topnavbar from '../common components/Topnavbar'
import Footer from '../common components/Footer'
import Productpagefirstsection from './Productpagefirstsection'
import Productpagetopbanner from './Productpagetopbanner'
import BookDemo from './Bookademo'
import { Helmet } from 'react-helmet';


const Productswholepage = () => {
  return (
    <>
     <Helmet>
        <title>Experience Seamless Document Handling with Presti Docs</title>
        <meta name="description" content= "Effortlessly manage your documents with Presti Docs. Enjoy swift retrieval, customizable workflows, and digitized processes, saving costs and streamlining operations." />
        <meta property="og:title" content="Unlock Efficiency with Presti Docs: Your Unified Solution" />
        <meta property="og:description" content="Discover Presti Docs, your all-in-one document management software. Enjoy a unified repository, lightning-fast retrieval, and robust audit trails." />
        <meta name="keywords" content="presti docs, document management, software" />
      </Helmet>
    <Topnavbar/>
    <Productpagetopbanner/>
    <Productpagefirstsection/>
    <BookDemo/>
    <Footer/>
    </>
  )
}

export default Productswholepage