import React from 'react'

const Customfirstsection = () => {
  return (
    <div className=' m-5 web-text'>
    <p>Our custom software development service is pivotal in unlocking your organization's full potential. We firmly believe that off-the-shelf solutions cannot match the power and flexibility of custom software. Let us empower your business with tailor-made software solutions. Reach out to us today to discover how our custom software development service can propel your success.

</p>

</div>
  )
}

export default Customfirstsection