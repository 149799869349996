import React from 'react';
import { Row, Col } from 'react-bootstrap';
import paraimage from "../../../assets/img/system_maintain__new1_.webp";
import { LuDot } from "react-icons/lu";


const Maintenancefirstsection = () => {
  return (
    <div className='web-text'>
      <Row className="justify-content-center">
        <Col md={12} className="mb-4">
          <p style={{ textAlign: 'justify', lineHeight: '2.2' }}>

            We understand that maintaining and supporting software is crucial for its optimal performance and longevity. That's why we offer a range of services designed to keep your software running smoothly and efficiently, allowing you to focus on your core business activities.
          </p>
        </Col>
        <Col md={6} className='web_page_img_div'>
          <img
            src={paraimage}
            alt='softawre maintenance service'
            className="custom-image-webservice"
            style={{ borderRadius: '10px' }}
          />
        </Col>
        <Col md={6} className='d-flex align-items-center'>
          <ul className='icon-list'>
            <li className="icon-list-item">
              <LuDot className="icon" />
              <span style={{ marginLeft: '10px' }}>Our team ensures the continuous health of your software through vigilant monitoring, identifying and addressing potential issues before they impact your operations.</span>
            </li>
            <li className="icon-list-item">
              <LuDot className="icon" />
              <span style={{ marginLeft: '10px' }}>Stay ahead with our regular software updates, incorporating the latest features, security patches, and performance enhancements to keep your system optimised and secure.</span>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default Maintenancefirstsection;

