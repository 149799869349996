import React from 'react'
import Topnavbar from '../../common components/Topnavbar'
import Newsdetail from './Newsdetail'
import Footer from '../../common components/Footer'

const Newsdetailwholepage = () => {
  return (
    <div>
    <Topnavbar/>
      <Newsdetail/>
      <Footer/> 
    </div>
  )
}

export default Newsdetailwholepage
