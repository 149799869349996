import React from 'react'
import aboutus from "../assets/img/home1/about-us.jpg"
// import expertconsultant from "../assets/img/new_about.jpg"
import expertconsultant from "../assets/img/tscfo_81.png"
import { FaUserGroup } from "react-icons/fa6";
import { TbTargetArrow } from "react-icons/tb";


const Aboutcompanyaboutsection = () => {
    return (
        <div className='body-wrapper'>
            <section class="about-us-wrapper section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-12 pr-5">
                            <div class="section-title mb-30">
                                <p>About Company</p>
                                <h1>Get’s IT Solutions For <br />
                                    Expert Consultants</h1>
                            </div>

                            <p class="pr-lg-5">Praathee Media - A leading software solution provider that specializes in developing innovative software solutions for businesses of all sizes. With over Seven years of experience, our team of skilled developers and designers have a proven track record of delivering high-quality, custom software applications that are tailored to meet our clients' specific needs.<br />

                                We understand that every business is unique and has its own set of challenges and goals.</p>

                            <div class="about-icon-box style-2">
                                <div class="icon">
                                    <FaUserGroup />
                                </div>
                                <div class="content">
                                    <h3>Professional Consultants</h3>
                                    <p>We provide research and analysis in your area of expertise to help management make business decisions.</p>
                                </div>
                            </div>
                            <div class="about-icon-box style-2">
                                <div class="icon">
                                    <TbTargetArrow />
                                </div>
                                <div class="content">
                                    <h3>IT Services & Managements</h3>
                                    <p>Revolutionize your IT operations with our expert IT Service Management services. </p>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-6 pl-lg-5 mt-5 mt-lg-0 col-12">
                            <div class="about-right-img">
                                <span class="dot-circle"></span>
                                <div className="about-us-img" style={{ backgroundImage: `url(${expertconsultant})` }} alt="Get’s IT Solutions For
Expert Consultants">
                                </div>
                                <span class="triangle-bottom-right"></span>
                            </div>
                        </div>
                    </div>

                    <div class="row mpt-50 pt-30">
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="single-features-item">
                                <div class="icon">
                                    <i class="flaticon-speech-bubble"></i>
                                </div>
                                <div class="content">
                                    <h3>Faster <br />Development </h3>
                                    <p>We are committed to the longevity and stability of our products</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="single-features-item">
                                <div class="icon">
                                    <i class="flaticon-unlock"></i>
                                </div>
                                <div class="content">
                                    <h3>24x7 Customer Support</h3>
                                    <p>With our experienced team, you can do more with less & faster.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="single-features-item">
                                <div class="icon">
                                    <i class="flaticon-mobile-app"></i>
                                </div>
                                <div class="content">
                                    <h3>Support & Maintenance</h3>
                                    <p>Working with us is the best way to solve your next business challenge.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Aboutcompanyaboutsection