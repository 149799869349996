import React from 'react'
import { LuMonitorCheck } from "react-icons/lu";
import { ImUserPlus } from "react-icons/im";
import { FaTools } from "react-icons/fa";
import { MdOutlineVideoSettings } from "react-icons/md";

const Howcanhelpyouservicesection = () => {
  return (
    <div className='body-wrapper'>
         <section class="servces-wrapper section-padding">
        <div class="container">
            <div class="row text-center mb-30">
                <div class="col-lg-8 p-lg-0 col-12 offset-lg-2">
                    <div class="section-title">
                        <p>How can help you</p>
                        <h1>We Provide Best  IT Solutions For Business
                            25 Years We Provide Solutions</h1>
                    </div>
                    <p class="mt-20">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam eaque quae abillo inventore</p>
                </div>
            </div>

            <div class="row justify-content-between">
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="single-service">
                        <div class="icon">
                            <LuMonitorCheck/>
                        </div>
                        <div class="content">
                            <h3>Quality Service</h3>
                            <p>Sed ut perspe unde omnis 
                                natus sit voluptatem accusa
                                doloremue laudantium</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="single-service">
                        <div class="icon">
                        <ImUserPlus/>
                        </div>
                        <div class="content">
                            <h3>Exclusive Members</h3>
                            <p>Sed ut perspe unde omnis 
                                natus sit voluptatem accusa
                                doloremue laudantium</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="single-service">
                        <div class="icon">
                            <FaTools/>
                        </div>
                        <div class="content">
                            <h3>Quality Support</h3>
                            <p>Sed ut perspe unde omnis 
                                natus sit voluptatem accusa
                                doloremue laudantium</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="single-service">
                        <div class="icon">
                            <MdOutlineVideoSettings/>
                        </div>
                        <div class="content">
                            <h3>Managment</h3>
                            <p>Sed ut perspe unde omnis 
                                natus sit voluptatem accusa
                                doloremue laudantium</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>  
    </div>
  )
}

export default Howcanhelpyouservicesection