import React from 'react';
import { Row, Col } from 'react-bootstrap';
import paraimage from "../../../assets/img/mobileapp-2.webp"; 
import { LuDot } from "react-icons/lu";


const Mobilefirstsection = () => {
  return (
    <div className='web-text'>
      <Row className="justify-content-center">
        <Col md={12} className="mb-4">
          <p style={{ textAlign: 'justify', lineHeight: '2.2' }}>

            We focused in providing tailored mobile applications to meet your specific needs, we infuse innovative ideas to deliver high-quality, user-friendly solutions. Our mobile app development service is dedicated to propelling your growth journey, offering unique solutions with a commitment to timely delivery. As a seasoned mobile application development company in India, we craft the , streamlining a seamless path for your clients to access products or services that cater to your business needs.
          </p>
        </Col>
        <Col md={6} className='web_page_img_div'>
            <img
              src={paraimage}
              alt='web page image'
              className="custom-image-webservice"
              style={{ borderRadius: '10px' }}
            />
        </Col>
        <Col md={6} className='d-flex align-items-center'>
          <ul className='icon-list'>
            <li className="icon-list-item">
              <LuDot className="icon" />
              <span style={{ marginLeft: '10px' }}>Take advantage of our expertise in creating higher quality apps for the iOS and Android platforms, expanding your market reach.</span>
            </li>
            <li className="icon-list-item">
              <LuDot className="icon" />
              <span style={{ marginLeft: '10px' }}>We prioritise the security of your mobile applications, adhering to industry standards and implementing robust measures to protect user data.</span>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default Mobilefirstsection;

