

import React from 'react'
import soluptons from "../../../assets/img/techex-landing-page/we-provide-solutopns-bg.png"


import { Row, Col } from 'react-bootstrap'

const Cloudimagesection = () => {
    return (
        <Row className="top-margin">
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(    ${soluptons})` }}>
                    <div className="content common-card-images">
                        <h3>SaaS</h3>
                        <hr />
                        <p>We are excel in creating scalable SaaS solutions, managing the entire development lifecycle for seamless user experiences and maximum ROI.       </p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>

                    <div className="content common-card-images">
                        <h3>PaaS</h3>
                        <hr />
                        <p>
                            Our PaaS development simplifies application management, offering tailored solutions for custom platforms or team development environments.
                        </p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                    <div className="content common-card-images">
                        <h3>IaaS </h3>
                        <hr />
                        <p>We provide IaaS development for scalable computing resources on AWS and Azure aiding businesses in managing your infrastructure solutions.</p>
                    </div>
                </a>
            </div>

            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                    <div className="content card-image common-card-images">
                        <h3>Customization</h3>
                        <hr />
                        <p>We tailor our services to meet your specific requirements and goals, ensuring a solution that fits your business needs.</p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                    <div className="content card-image common-card-images">
                        <h3>Reliability</h3>
                        <hr />
                        <p>We prioritize reliability and uptime, ensuring that your cloud applications are always available and performing optimally</p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>

                    <div className="content common-card-images">
                        <h3>Expertise</h3>
                        <hr />
                        <p> Our team consists of seasoned cloud developers with years of experience in designing and implementing cloud solutions.</p>
                    </div>
                </a>
            </div>

        </Row>
    )
}

export default Cloudimagesection