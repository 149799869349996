import React from 'react'
import funfactline from "../assets/img/funfact-line.png"
import { IoDiamondSharp } from "react-icons/io5";
import { FaDraftingCompass } from "react-icons/fa";
import { BsStars } from "react-icons/bs";
import { LiaTrophySolid } from "react-icons/lia";


const Countsservicesection = () => {
  return (
    <div className='body-wrapper'>
        <section class="funfact-wrapper bottom text-white">
        <div class="container">
            <div class="funfact-content-grid bg-cover" style={{backgroundImage:`url(${funfactline})`}}>
                <div class="single-funfact-item">
                    <div class="icon">
                        <IoDiamondSharp/>
                    </div>
                    <h3>368</h3>
                    <p>Project Completed</p>
                </div>
                <div class="single-funfact-item">
                    <div class="icon">
                        <FaDraftingCompass/>
                    </div>
                    <h3>785</h3>
                    <p>Expert Consultants</p>
                </div>
                <div class="single-funfact-item">
                    <div class="icon">
                        <BsStars/>
                    </div>
                    <h3>896</h3>
                    <p>5 Stars Rating</p>
                </div>
                <div class="single-funfact-item">
                    <div class="icon">
                        <LiaTrophySolid/>
                    </div>
                    <h3>125</h3>
                    <p>Awards Winning</p>
                </div>
            </div>
        </div>
    </section> 
    </div>
  )
}

export default Countsservicesection