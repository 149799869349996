import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TbSquareRoundedNumber1Filled, TbSquareRoundedNumber2Filled, TbSquareRoundedNumber3Filled, TbSquareRoundedNumber4Filled } from "react-icons/tb";
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import demoactions from "../redux/book_demo/action";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const BookDemoPage = () => {

  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [preferredTime, setPreferredTime] = useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (event) => {
    setPreferredTime(event.target.value);
  };

  // const generateTimeSlots = () => {
  //   const slots = [];
  //   for (let hour = 9; hour < 23; hour++) { // Loop for hours from 9 to 22
  //     for (let minute = 0; minute < 60; minute += 30) { // Loop for minutes in 30-minute intervals
  //       const startTime = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
  //       const endTimeHour = minute === 30 ? hour + 1 : hour; 
  //       const endTimeMinute = minute === 30 ? '00' : '30'; 
  //       const endTime = `${String(endTimeHour).padStart(2, '0')}:${endTimeMinute}`;
  //       const timeSlot = `${startTime} - ${endTime}`;
  //       slots.push(timeSlot); 
  //     }
  //   }
  //   slots.push("22.30 - 23.00");
  //   return slots;
  // };

  const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 9; hour <= 23; hour++) { // Loop for hours from 9 to 23
      for (let minute = 0; minute < 60; minute += 30) { // Loop for minutes in 30-minute intervals
        if (hour === 23 && minute === 30) {
          break; // Skip generating the last time slot if it's 11:30 PM
        }
        const startTime = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
        const endTimeHour = minute === 30 ? hour + 1 : hour;
        const endTimeMinute = minute === 30 ? '00' : '30';
        const endTime = `${String(endTimeHour).padStart(2, '0')}:${endTimeMinute}`;
        const timeSlot = `${startTime} - ${endTime}`;
        slots.push(timeSlot);
      }
    }
    return slots;
  };

  const timeSlots = generateTimeSlots();
  console.log(timeSlots);

  // const timeSlots = generateTimeSlots();
  // console.log(timeSlots);


  const onSubmit = (data) => {
    // Convert selected date to UTC
    const preferred_date = selectedDate.toISOString().split('T')[0];

    const formData = {
      ...data,
      preferred_date: preferred_date,
      preferred_time: preferredTime

    };
    dispatch({
      type: demoactions.GET_A_DEMO,
      payload: {
        data: formData,
        navigation: navigate
      }
    });
  };

  return (
    <div className='container '>
      <Row className=" mt-3">
        <Col sm={12} md={6} className="mt-5">
          <p className='bookademoptag'>Ready to transform your document management experience?
            Contact us for a personalized demo and discover how PrestiDocs can simplify your workflow, enhance security, and empower your team.
            PrestiDocs - Simplifying Documents, Empowering Productivity!
          </p>
          <ul type="i">
            <h4>Digitize Paper Hassles:</h4>
            <p className='bookademoptag'>Seamlessly transition from physical to digital with PrestiDocs' document capture tools.</p>
            <h4> Cost Savings:</h4>
            <p className='bookademoptag'>Streamline processes, save on resources, and experience significant cost savings.</p>
            <h4> User-Friendly Interface: </h4>
            <p className='bookademoptag'>Quick adoption with an intuitive design. Less learning, more productivity.</p>
            <h4>  Expert Training and Support:</h4>
            <p className='bookademoptag'>Dedicated training sessions and fast, efficient customer support ensure a seamless experience</p>
          </ul>
        </Col>

        <Col sm={12} md={6} className="mt-5">
          <div className="demo-content">
            <div className="demo-form" >
              <h2>Book a Demo</h2>
              <form onSubmit={handleSubmit(onSubmit)} >
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Name"
                    {...register('name', { required: true })}
                  />
                  {errors.name && <span className="error-message">Name is required</span>}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Company Name"
                    {...register('company_name', { required: true })}
                  />
                  {errors.company_name && <span className="error-message">Company Name is required</span>}
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Email Address"
                    {...register('email', { required: true })}
                  />
                  {errors.email && <span className="error-message">Email is required</span>}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Phone Number"
                    {...register('phone_number', {
                      required: true,
                      pattern: {
                        value: /^\d{10}$/,
                        message: "Invalid phone number"
                      }
                    })}
                  />
                  {errors.phone_number && <span className="error-message">{errors.phone_number.message}</span>}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Description"
                    {...register('description')}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Country"
                    {...register('country')}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="form-group" style={{ position: 'relative', marginRight: '10px' }}>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select Date"
                      minDate={new Date()} // Set minDate to today's date
                      popperPlacement="top" // Position the popper at the top
                    />
                  </div>
                  <div className="form-group">
                    <select
                      value={preferredTime}
                      onChange={handleTimeChange}
                    >
                      <option value="">Select preferred time</option>
                      {generateTimeSlots().map((time, index) => (
                        <option key={index} value={time}>{time}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      placeholder="IST"
                      style={{
                        border: '1px solid #ccc',
                        padding: '9px',
                        width: '80%',
                        boxSizing: 'border-box',
                      }}
                      readOnly
                    />
                  </div>
                </div>
                <button type="submit">Book Demo</button>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BookDemoPage;
