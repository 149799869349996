import actions from "./action";

const initState = {
    DemoList: [],
}

const BookDemoReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.SET_A_DEMO:
            {
                return {
                    ...state,
                    DemoList: action.payload
                }
            }

        default: return state;
    }
}
export default BookDemoReducer;