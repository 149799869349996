import React from 'react'
import Topnavbar from '../common components/Topnavbar'
import Footer from '../common components/Footer'
import Contacttopbanner from './Contacttopbanner'
import Contactfirstsection from './Contactfirstsection'
import { Helmet } from 'react-helmet';



const ContactWholePage = () => {
  return (
    <div>
       <Helmet>
        <title>Contact Praathee Media - Your Premier Software Solutions Partner</title>
        <meta name="description" content= "Get in touch with Praathee Media today for innovative and custom software solutions. Our team is ready to assist you with your technology needs." />
        <meta property="og:title" content="Reach Out to Praathee Media for Expert Software Assistance" />
        <meta property="og:description" content="Ready to elevate your business with innovative software solutions? Connect with us to explore bespoke software solutions designed to drive your business forward. " />
      </Helmet>

        <Topnavbar/>
        <Contacttopbanner/>
        <Contactfirstsection/>
        <Footer/>

    </div>
  )
}

export default ContactWholePage