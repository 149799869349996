const actions = {
    GET_A_DEMO: "GET_A_DEMO",
    SET_A_DEMO: "SET_A_DEMO",



    GET_A_QUOTE: "GET_A_QUOTE",
    GET_CAREER_FORM: "GET_CAREER_FORM",
  
}
export default actions;