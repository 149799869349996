import React from 'react'
import Topnavbar from '../../../common components/Topnavbar'
import Enterprisetopbanner from './Enterprisetopbanner'
import Enterprisefirstsection from './Enterprisefirstsection'
import Enterpriseimagesection from './Enterpriseimagesection'
import Footer from '../../../common components/Footer'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet';

const Enterprisewholepage = () => {
  return (
    <>
      <Helmet>
        <title>Top ERP Software Development Company by Praathee Media</title>
        <meta name="description" content="Praathee's expert team specializes in custom ERP software development to streamline your business processes efficiently. Contact us today for a consultation!" />
        <meta property="og:title" content="Leading ERP Software Development Company - Trusted Solutions" />
        <meta property="og:description" content="Looking for reliable ERP software solutions? Boost your business efficiency with our top-notch ERP software solutions tailored to meet your specific needs." />
        <meta name="keywords" content="erp software development, company, enterprise software development, enterprise custom software development, enterprise application development" />
        <link rel="canonical" href="https://www.praathee.com/erp-development" />
      </Helmet>
      <Topnavbar />
      <Enterprisetopbanner />
      <Container>
        <div className='my-5'>
          <Enterprisefirstsection />
        </div>
        <div className='mb-5'>
          <Enterpriseimagesection />
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default Enterprisewholepage


