import React from 'react'
import Topnavbar from '../../common components/Topnavbar'
import Footer from '../../common components/Footer'
import Servicedetailstopbanner from './Servicedetailstopbanner'
import Servicedetailsfirstsection from './Servicedetailsfirstsection'

const Servicedetailwholepage = () => {
  return (
    <div>
        <Topnavbar/>
        <Servicedetailstopbanner/>
        <Servicedetailsfirstsection/>
        <Footer/>
    </div>
  )
}

export default Servicedetailwholepage