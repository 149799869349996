
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import paraimage from "../../../assets/img/Block-Chain-1_new_.webp"; 
import { LuDot } from "react-icons/lu";


const Blockchainfirstsection = () => {
  return (
    <div className='web-text'>
      <Row className="justify-content-center">
        <Col md={12} className="mb-4">
          <p style={{ textAlign: 'justify', lineHeight: '2.2' }}>

            As a leading provider of blockchain development services catering to businesses of all sizes, we offer a comprehensive range of blockchain solutions engineered to empower our clients in leveraging the transformative potential of this revolutionary technology. Our team comprises seasoned blockchain professionals well-versed in the latest trends, techniques, and industry best practices
          </p>
        </Col>
        <Col md={6} className='web_page_img_div'>
            <img
              src={paraimage}
              alt='Block chain development'
              className="custom-image-webservice"
              style={{ borderRadius: '10px' }}
            />
        </Col>
        <Col md={6} className='d-flex align-items-center'>
          <ul className='icon-list'>
            <li className="icon-list-item">
              <LuDot className="icon" />
              <span style={{ marginLeft: '10px' }}>Utilise the power of our seasoned developers with extensive knowledge and hands-on experience in blockchain technology.</span>
            </li>
            <li className="icon-list-item">
              <LuDot className="icon" />
              <span style={{ marginLeft: '10px' }}> Benefit from custom-tailored blockchain solutions catering to your unique business requirements, ensuring optimal functionality.</span>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default Blockchainfirstsection;
