import { combineReducers } from 'redux';
import BookDemoReducer from "./book_demo/reducer";


const rootReducer = combineReducers({
    BookDemoReducer,
  
});

export default rootReducer;
