import React from 'react'
import Topnavbar from '../common components/Topnavbar'
import Footer from '../common components/Footer'
import pagebanner from "../assets/img/page-banner.jpg"
import casep1 from "../assets/img/case/p1.jpg"
import casep2 from "../assets/img/case/p2.jpg"
import casep3 from "../assets/img/case/p3.jpg"
import casep4 from "../assets/img/case/p4.jpg"
import casehead from "../assets/img/case/case-head.png"
import brand1 from "../assets/img/LogNCM12.png"
import brand2 from "../assets/img/EonianLogo.webp"
import brand3 from "../assets/img/yf.png"
import brand4 from "../assets/img/reliance_logo.jpg"
import brandb4 from "../assets/img/brand/b4.png"


const Projectdetails = () => {
  return (
    <>
    <Topnavbar/>
    <section class="page-banner-wrap bg-cover" style={{backgroundImage:`url(${pagebanner})`}}>
        <div class="banner-text">techex</div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 col-12">
                    <div class="page-heading text-white">
                        <div class="page-title">
                            <h1>Case Details</h1>
                        </div>
                    </div>

                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a href="/">Home</a></li>
                          <li class="breadcrumb-item active" aria-current="page">case details</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </section>
    <section class="case-study-post-wrapper section-padding">
        <div class="container">
            <div class="case-grid-photos">
                <div class="single-photo-grid">
                    <img src={casep1} alt=""/>
                </div>
                <div class="single-photo-grid">
                    <img src={casep2} alt=""/>
                </div>
                <div class="single-photo-grid">
                    <img src={casep3} alt=""/>
                </div>
                <div class="single-photo-grid">
                    <img src={casep4} alt=""/>
                </div>
            </div>
            <div class="case-contents-wrap">
                <div class="row">
                    <div class="col-lg-8 col-12">
                        <div class="case-details-content mr-0 mr-lg-5">
                            <h2>A Real Store For Digital Solutions</h2>
                            <p>wquis autem vel eum iure reprehenderit quiin  voluptate velit esse quam nihil molestiae onsequatur, vel illum solorem eum fugiat quo voluptas nulla pariatur laudantium totam  rem aperquae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quiluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. </p>
                            
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepte ursint occaecat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            
                            <h3>How It Works Benefit For Projects</h3>
                            <p>On the other hand, we denounce with righteous indignation and dislike men who are beguiled and demoralized by the charms of pleasure of the moment so blinded by desire that they cannot foresee the pain and trouble that are bound to ensue and equals.</p>

                            <ul>
                                <li>Need To Know To Increase Mobile Checkout Conversions</li>
                                <li>Improve Your Design Process With Data-Based</li>
                                <li>Analyzing Your Company’s Social Media Presence With IBM Watson</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-12">
                        <div class="case-info-card">
                            <div class="case-head bg-cover" style={{backgroundImage:`url(${casehead})`}}>
                                <h3>Project Details</h3>
                            </div>
                            <div class="project-data">
                                <div class="single-info-item">
                                    <div class="left-data">
                                        Clients
                                    </div>
                                    <div class="right-data">
                                        Robert C. Saavedra
                                    </div>
                                </div>
                                <div class="single-info-item">
                                    <div class="left-data">
                                        Project
                                    </div>
                                    <div class="right-data">
                                        IT Consulting
                                    </div>
                                </div>
                                <div class="single-info-item">
                                    <div class="left-data">
                                        Service
                                    </div>
                                    <div class="right-data">
                                        Digital Solutions
                                    </div>
                                </div>
                                <div class="single-info-item">
                                    <div class="left-data">
                                        Category
                                    </div>
                                    <div class="right-data">
                                        IT Services
                                    </div>
                                </div>
                                <div class="single-info-item">
                                    <div class="left-data">
                                        Date
                                    </div>
                                    <div class="right-data">
                                        30 Aug 2021
                                    </div>
                                </div>
                                <div class="single-info-item">
                                    <div class="left-data">
                                        share
                                    </div>
                                    <div class="right-data">
                                        <div class="social-link">
                                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                                            <a href="#"><i class="fab fa-twitter"></i></a>
                                            <a href="#"><i class="fab fa-linkedin"></i></a>
                                            <a href="#"><i class="fab fa-youtube"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="client-brand-logo-wrap">
        <div class="container">
            <div class="brand-carousel-active pt-60 pb-60 d-flex justify-content-between owl-carousel">
                <div class="single-client">
                    <img src={brand1} alt=""/>
                </div>
                <div class="single-client">
                    <img src={brand2} alt=""/>
                </div>
                <div class="single-client">
                    <img src={brand3} alt=""/>
                </div>
                <div class="single-client">
                    <img src={brand4} alt=""/>
                </div>
                {/* <div class="single-client">
                    <img src={brandb4} alt=""/>
                </div> */}
            </div>
        </div>
    </div>
    
    <Footer/>

    </>
  )
}

export default Projectdetails