import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './Sagas';
import reducers from './Reducer';

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({ ...reducers });

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

export default store;
