import "../src/styles/styles.css"
import "../src/styles/animate.css"
import "../src/styles/bootstrap.min.css"
import "../src/styles/style1.css"
import Routers from "./routes";



function App() {
  return (
    <div className="App">
      <Routers/>
    </div>
  );
}

export default App;
