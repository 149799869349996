import React from 'react'
import Topnavbar from "../common components/Topnavbar";
import Footer from "../common components/Footer";
import Casestudytopbanner from './Casestudytopbanner';
import Latestcasestudies from './Latestcasestudies';
import Listofclientscasestudysection from './Listofclientscasestudysection';

const Casestudywholepage = () => {
  return (
    <div>
      <Topnavbar/>
      <Casestudytopbanner/>
      <Latestcasestudies/>
      <Listofclientscasestudysection/>
      <Footer/> 
    </div>
  )
}

export default Casestudywholepage