import React from 'react'
import soluptons from "../../../assets/img/techex-landing-page/we-provide-solutopns-bg.png"


import { Row, Col } from 'react-bootstrap'

const Blockchainimagesection = () => {
    return (
        <Row className="top-margin">
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(    ${soluptons})` }}>
                    <div className="content common-card-images">
                        <h3>Custom Blockchain Solutions</h3>
                        <hr />
                        <p>Tailored to your business requirements, our solutions leverage distributed ledger technology to enhance transparency, security, and efficiency</p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>

                    <div className="content common-card-images">
                        <h3>Smart Contract Development</h3>
                        <hr />
                        <p>Our experts develop self-executing contracts for automate processes, and maintain trust,, and ensure seamless compliance with regulatory standards</p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                    <div className="content common-card-images">
                        <h3>Blockchain API Integration</h3>
                        <hr />
                        <p>We seamlessly integrate blockchain into your systems to facilitate  data sharing.Empowering you to achieve efficiency in all aspects of operations.</p>
                    </div>
                </a>
            </div>

            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                    <div className="content card-image common-card-images">
                        <h3>Tokenization and ICO Development</h3>
                        <hr />
                        <p>From asset tokenization to ICO launches, we guide you seamlessly through execution</p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                    <div className="content card-image common-card-images">
                        <h3>End-to-End <br /> Collaboration</h3>
                        <hr />
                        <p>We work closely with our clients to understand their unique needs and deliver tailored solutions.</p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>

                    <div className="content common-card-images">
                        <h3>High Quality Blockchain Solutions</h3>
                        <hr />
                        <p>We adhere to best quality standards to ensure that our blockchain solutions are secure, scalable, and reliable.</p>
                    </div>
                </a>
            </div>

        </Row>
    )
}

export default Blockchainimagesection