import React, { useRef, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import demoactions from "../redux/book_demo/action";
import 'react-toastify/dist/ReactToastify.css';
import { Modal, ModalBody, Button, Row, Col } from 'react-bootstrap';

export const Careerformmodal = ({ showModal, closeModal }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useRef();



  const [resume, setResume] = useState(null);
  const [photo, setPhoto] = useState(null);

  const handleAttachmentChange = (e, type) => {
    const file = e.target.files[0];
    if (type === 'resume') {
      setResume(file);
    } else if (type === 'photo') {
      setPhoto(file);
    }
  };

  const sendEmail = (data) => {
    const formData = {
      ...data,
      resume: resume,
      photo: photo
    };

    dispatch({
      type: demoactions.GET_CAREER_FORM,
      payload: {
        data: formData,
        navigation: navigate
      }
    });
  };

  return (
    <>
      <Modal show={showModal} onHide={closeModal}  backdrop="static" keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Submit Your Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="contact-form">
            <form action="" className="row conact-form" ref={form} onSubmit={handleSubmit(sendEmail)}>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="fname">Full Name*</label>
                  <input
                    type="text"
                    id="fname"
                    placeholder="Enter Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && <span className="error-message">Name is required</span>}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="email">Email Address*</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter Email Address"
                    {...register("email", { required: true })}
                  />
                  {errors.email && <span className="error-message">Email is required</span>}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="phone">Phone Number*</label>
                  <input
                    type="text"
                    id="phone"
                    placeholder="Enter Phone Number"
                    {...register("phonenumber", {
                      required: true,
                      pattern: {
                        value: /^\d{10}$/,
                        message: "Please enter a 10-digit phone number"
                      }
                    })}
                  />
                  {errors.phonenumber && <span className="error-message">{errors.phonenumber.message}</span>}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="current_location">Current Location</label>
                  <input type="text" id="current_location" placeholder="Enter Current Loaction" {...register("current_location", { required: true })} />
                  {errors.email && <span className="error-message">Current Location required</span>}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="native_location">Native Location</label>
                  <input type="text" id="native_location" placeholder="Enter Native Loaction" {...register("native_location", { required: true })} />
                  {errors.email && <span className="error-message">Native Location required</span>}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="position_for_applied">Position Applied For</label>
                  <input type="text" id="position_for_applied" placeholder="Enter Position Applied For" {...register("position_for_applied", { required: true })} />
                  {errors.email && <span className="error-message">Position For Applied required</span>}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="year_of_experience">Years of experience</label>
                  <input type="text" id="year_of_experience" placeholder="Enter Years of Experience" {...register("year_of_experience", { required: true })} />
                  {errors.email && <span className="error-message">Experience required</span>}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="expected_ctc">Expected Salary</label>
                  <input type="text" id="expected_ctc" placeholder="Enter Expected Salary" {...register("expected_ctc", { required: true })} />
                  {errors.email && <span className="error-message">Expected Salary required</span>}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="previous_current_ctc">Pervious Salary</label>
                  <input type="text" id="previous_current_ctc" placeholder="Enter Pervious Salary" {...register("previous_current_ctc", { required: true })} />
                  {errors.email && <span className="error-message">Pervious Salary required</span>}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="join_immediately">Able To Join Immediately?</label>
                  <select id="join_immediately" {...register("join_immediately", { required: true })}>
                    <option value="">Select Option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {errors.email && <span className="error-message">Field is required</span>}
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="notice_period">Notice period</label>
                  <input type="text" id="notice_period" placeholder="Enter Notice Peroid" {...register("notice_period", { required: true })} />
                  {errors.email && <span className="error-message">Field is required</span>}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="resume">Resume</label>
                  <input type="file" id="resume" name="resume" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => handleAttachmentChange(e, 'resume')} />
                  {errors.email && <span className="error-message">File is required</span>}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="single-personal-info">
                  <label htmlFor="photo">Photo</label>
                  <input type="file" id="photo" name="photo" accept="image/*" onChange={(e) => handleAttachmentChange(e, 'photo')} />
                  {errors.email && <span className="error-message">File is required</span>}
                </div>
              </div>
              <div className="col-md-12 col-12 text-center">
                <button type="submit" className="theme-btn">Apply <i className="fas fa-arrow-right"></i></button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
