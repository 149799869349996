import React from 'react'
import brand1 from "../assets/img/LogNCM12.png"
import brand2 from "../assets/img/EonianLogo.webp"
import brand3 from "../assets/img/yf.png"
import brand4 from "../assets/img/reliance_logo.jpg"
import brandb4 from "../assets/img/brand/b4.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Listofclientscasestudysection = () => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          }
        ]
      };
  return (
    <div className='body-wrapper'>
        <section>
         <div className="client-brand-logo-wrap techex-landing-page pt-0">
            <div className="container">
                <div className="brand-carousel-active d-flex justify-content-between owl-carousel mt-3">
                          <div className="single-client ">
                              <img src={brand4} alt=""/>
                          </div>
                          {/* <div className="single-client ">
                              <img src={brandb4} alt=""/>
                          </div> */}
                          <div className="single-client ">
                              <img src={brand2} alt=""/>
                          </div>
                          <div className="single-client ">
                              <img src={brand1} alt=""/>
                          </div>
                          <div className="single-client ">
                              <img src={brand3} alt=""/>
                          </div>
                </div>
            </div>
        </div>
        </section>
    </div>
  )
}

export default Listofclientscasestudysection