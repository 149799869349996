import React from 'react'
import soluptons from "../../../assets/img/techex-landing-page/we-provide-solutopns-bg.png"


import { Row, Col } from 'react-bootstrap'

const Webimagesection = () => {
    return (
        <Row className="top-margin">
<div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(    ${soluptons})` }}>
 <div className="content common-card-images">
                            <h3>Custom Application Development</h3>
                            <hr />
                            <p>We prioritize Custom built responsive design to ensure flawless performance across all devices and screen sizes. </p>
                        </div>
                    </a>
                </div>
                <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>

                        <div className="content common-card-images">
                            <h3>E-Commerce Development</h3>
                            <hr />
                            <p>
                                We build large-scale applications capable of handling today's technological demands easily.
                            </p>
                        </div>
                    </a>
                </div>
                <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                        <div className="content card-image common-card-images">
                            <h3>Creative UI / UX <br /> Design</h3>
                            <hr />
                            <p>We bring together your vision and what users want by creating stunning interfaces and Overall Experience</p>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                        <div className="content common-card-images">
                            <h3>Application Testing and QA</h3>
                            <hr />
                            <p>We offer world-class web solutions with easy integration to ensure sustainable business operations.</p>
                        </div>
                    </a>
                </div>
                <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                        <div className="content card-image common-card-images">
                            <h3>Maintenance & <br />Support </h3>
                            <hr />
                            <p>Build a well-constructed website and web application  functionality & interactivity</p>
                        </div>
                    </a>
                </div>
                <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>

                        <div className="content common-card-images">
                            <h3>Seamless Web API Integration</h3>
                            <hr />
                            <p>We provide web solutions with easy integration ensuring sustainable business for your Organizations.</p>
                        </div>
                    </a>
                </div>
        </Row>
    )
}

export default Webimagesection