import React from 'react'
import Webtopbanner from './Webtopbanner'
import Webfirstsection from './Webfirstsection'
import Webimagesection from './Webimagesection'
import Topnavbar from '../../../common components/Topnavbar'
import Footer from '../../../common components/Footer'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet';

const Webwholepage = () => {
  return (
    <>
     <Helmet>
        <title>Praathee Media: Premier Web Application Development Services</title>
        <meta name="description" content="Elevate your online presence with Praathee Media's expert web app development services. Tailored solutions to meet your business needs and exceed expectations." />
        <meta property="og:title" content="Custom Web Application Solutions by Praathee Media" />
        <meta property="og:description" content="Transform your business strategies into reality with transformative web app development from Praathee Media. Custom, scalable, and impactful web apps await." />
        <meta name="keywords" content="web app development, web app developers, web app development company, web application development, web app development service" />
      </Helmet>
      <Topnavbar />
      <Webtopbanner />
      <Container> 
        <div className='my-5'>
        <Webfirstsection />
        </div>
        <div className='mb-5'>
        <Webimagesection />
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default Webwholepage