import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ImUserTie } from "react-icons/im";
import Topnavbar from '../common components/Topnavbar';
import Footer from '../common components/Footer';
import pagebanner from "../assets/img/karriere1.png"
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaHandshakeSimple } from "react-icons/fa6";
import { FaMapLocationDot } from "react-icons/fa6";
import { BsArrowRightShort } from "react-icons/bs";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import demoactions from "../redux/book_demo/action";

const Contactfirstsection = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useRef();
  const [document, setAttachment] = useState(null);

  const sendEmail = (data) => {
    console.log(data);
    const formData = {
      ...data,
      resume: document,
      // document: attachment,
    };
    console.log("console-1", formData)
    dispatch({
      type: demoactions.GET_A_QUOTE,
      payload: {
        data: formData,
        navigation: navigate
      }
    });
  };

  const handleAttachmentChange = (e) => {
    setAttachment(e.target.files[0]);

  };


  return (
    <div>
      <section className="contact-page-wrap section-padding">
        <div className="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-12">
              <div class="single-contact-card card1">
                {/* <div class="top-part" style={{ display: 'flex', alignItems: 'center' }}>
                  <div class="icon">
                    <ImUserTie />
                  </div>
                  <div class="title">
                    <h4 style={{ margin: '0' }}>HR TEAM</h4>
                  </div>
                </div> */}
                <div className="top-part" style={{ display: 'flex', alignItems: 'center' }}>
  <div className="icon">
    <ImUserTie />
  </div>
  <div className="title">
      <h4 style={{ margin: '0' }}>HR TEAM</h4>
    
  </div>
</div>


                <div class="bottom-part">
                  <div class="info">
                    <p>hr@praathee.com</p>
                    <p>+91 89256 54157</p>
                  </div>
                  <div class="icon">
                  <a href="tel:+1234567890" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <BsArrowRightShort />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="single-contact-card card2">
                <div class="top-part" style={{ display: 'flex', alignItems: 'center' }}>
                  <div class="icon">
                    <FaHandshakeSimple />
                  </div>
                  <div class="title">
                    <h4>Sales Team</h4>
                    {/* <span>call us asap anytime</span> */}
                  </div>
                </div>
                <div class="bottom-part">
                  <div class="info">
                    <p>sales@praathee.com</p>
                    <p>+91 90432 04157</p>
                  </div>
                  <div class="icon">
                  <a href="tel:+1234567890" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <BsArrowRightShort />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="single-contact-card card3">
                <div class="top-part" style={{ display: 'flex', alignItems: 'center' }}>
                  <div class="icon">
                    <FaMapLocationDot />
                  </div>
                  <div class="title">
                    <h4>Our Address</h4>
                  </div>
                </div>
                  <div class="bottom-part">
                    <div class="info">
                    <div class="icon">
                    <a href="https://maps.google.com/?q=11/4, Pooja Garden, Kalapatti Main Rd, Civil Aerodrome Post, Coimbatore, India-641 014" target="_blank">
                      <BsArrowRightShort/> 
                      </a>
                    </div>
                      <p>Innovspace Coworking</p>
                      <p>No. 11/4, Pooja Garden,</p>
                      <p>Kalapatti Main Rd,Civil Aerodrome Post</p>
                      <p> Coimbatore,India-641 014</p>
                    </div>
                  </div>      
              </div>      
            </div>
          </div>
          <div className='container'>
            <div className="row section-padding pb-0 ">
              <div className="col-12 text-center mb-20">
                <div className="section-title">
                  <p>Send us a message</p>
                  <h1>Don’t Hesitate To Contact Us <br /> Say Hello or Message</h1>
                </div>
              </div>
              {/* <div className="col-12 col-lg-12">
                <div className="contact-form">
                  <form action="" className="row conact-form" ref={form} onSubmit={handleSubmit(sendEmail)}>
                    <div className="col-md-6 col-12">
                      <div className="single-personal-info">
                        <label htmlFor="fname">Full Name</label>
                        <input type="text" id="fname" placeholder="Enter Name" {...register("name", { required: true })} />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="single-personal-info">
                        <label htmlFor="company_name">Company Name</label>
                        <input type="text" id="company_name" placeholder="Enter Company Name" {...register("company_name", { required: true })} />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="single-personal-info">
                        <label htmlFor="email">Email Address</label>
                        <input type="email" id="email" placeholder="Enter Email Address" {...register("email", { required: true })} />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="single-personal-info">
                        <label htmlFor="phone">Phone Number</label>
                        <input type="text" id="phone" placeholder="Enter Number" {...register("phone_number", { required: true })} />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="single-personal-info">
                        <label htmlFor="county">County</label>
                        <input type="text" id="county" placeholder="Enter County" {...register("country", { required: true })} />
                      </div>

                    </div>
                    <div className="col-md-6 col-12">
                      <div className="single-personal-info">
                        <label htmlFor="document">Attachment</label>
                        <input type="file" id="document" name="document" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={handleAttachmentChange} />
                      </div>
                    </div>
                    <div className="col-md-12 col-12">
                      <div className="single-personal-info">
                        <label htmlFor="description">Description</label>
                        <textarea id="description" placeholder="Enter Message" {...register("description")}></textarea>
                        {/* <textarea id="description" placeholder="Enter message"></textarea>  */}
                      {/* </div>
                    </div>
                    <div className="col-md-12 col-12 text-center">
                      <button type="submit" className="theme-btn">Send Message <i className="fas fa-arrow-right"></i></button>
                    </div>
                  </form>
                </div>
              </div> */} 
                  <div className="col-12 col-lg-12">
                  <div className="contact-form">
                    <form action="" className="row conact-form" onSubmit={handleSubmit(sendEmail)}>
                      <div className="col-md-6 col-12">
                        <div className="single-personal-info">
                          <label htmlFor="fname">Full Name</label>
                          <input type="text" id="fname" placeholder="Enter Name" {...register("name", { required: true })} />
                          {errors.name && <span className="error-message">Name is required</span>}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="single-personal-info">
                          <label htmlFor="company_name">Company Name</label>
                          <input type="text" id="company_name" placeholder="Enter Company Name" {...register("company_name", { required: true })} />
                          {errors.company_name && <span className="error-message">Company Name is required</span>}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="single-personal-info">
                          <label htmlFor="email">Email Address</label>
                          <input type="email" id="email" placeholder="Enter Email Address" {...register("email", { required: true })} />
                          {errors.email && <span className="error-message">Email is required</span>}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="single-personal-info">
                          <label htmlFor="phone">Phone Number</label>
                          <input type="text" id="phone" placeholder="Enter Number" {...register("phone_number", { required: true })} />
                          {errors.phone_number && <span className="error-message">Phone Number is required</span>}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="single-personal-info">
                          <label htmlFor="country">Country</label>
                          <input type="text" id="country" placeholder="Enter Country" {...register("country", { required: true })} />
                          {errors.country && <span className="error-message">Country is required</span>}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="single-personal-info">
                          <label htmlFor="document">Attachment</label>
                          <input type="file" id="document" name="document" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={handleAttachmentChange} />
                        </div>
                      </div>
                      <div className="col-md-12 col-12">
                        <div className="single-personal-info">
                          <label htmlFor="description">Description</label>
                          <textarea id="description" placeholder="Enter Message" {...register("description")}></textarea>
                        </div>
                      </div>
                      <div className="col-md-12 col-12 text-center">
                        <button type="submit" className="theme-btn">Send Message <i className="fas fa-arrow-right"></i></button>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contactfirstsection;
