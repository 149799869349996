import React from 'react';
import { Row, Col } from 'react-bootstrap';
import paraimage from "../../../assets/img/ERP-New-1.webp";

import { LuDot } from "react-icons/lu";


const Enterprisefirstsection = () => {
  return (
    <div className='web-text'>
      <Row className="justify-content-center">
        <Col md={12} className="mb-4">
          <p style={{ textAlign: 'justify', lineHeight: '2.2' }}>

            We believe in a collaborative approach to software development, closely work with our clients to comprehend their objectives, challenges, and vision. Our team, comprising experienced developers, architects, and project managers, employs industry best practices and agile methodologies to deliver top-notch software solutions that enhance efficiency, productivity, and innovation. Leveraging our expertise in enterprise software development, we empower businesses to streamline operations, boost productivity, and foster growth.
          </p>
        </Col>
        <Col md={6} className='web_page_img_div'>
          <img
            src={paraimage}
            alt='web page image'
            className="custom-image-webservice"
            style={{ borderRadius: '10px' }}
          />
        </Col>
        <Col md={6} className='d-flex align-items-center'>
          <ul className='icon-list'>
            <li className="icon-list-item">
              <LuDot className="icon" />
              <span style={{ marginLeft: '10px' }}>Our adaptable and scalable software designs ensure your enterprise applications can grow seamlessly with your business, accommodating evolving requirements.</span>
            </li>
            <li className="icon-list-item">
              <LuDot className="icon" />
              <span style={{ marginLeft: '10px' }}>Stay ahead with our integration of the latest technologies, ensuring your enterprise software remains innovative, competitive, and future-ready.</span>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default Enterprisefirstsection;
