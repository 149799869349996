import React, { useRef,useState  } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Topnavbar from '../common components/Topnavbar';
import FooterForCareer from '../common components/FooterforCareer';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form'; 
import demoactions from "../redux/book_demo/action";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export const Careerform = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useRef();
  const [document, setAttachment] = useState(null);

  const sendEmail = (data) => {
    console.log(data);
    const formData = {
      ...data,
      resume: document,
    };

    dispatch({
      type: demoactions.GET_A_QUOTE,
      payload: {
        data:formData,
        navigation: navigate
      }
    });
  };

  const handleAttachmentChange = (e) => {
    setAttachment(e.target.files[0]);
  };


  return (
    <>
      <Topnavbar />
      <div className='container'>
            <div className="row section-padding pb-0 ">
              <div className="col-12 text-center mb-20">
                <div className="section-title">
                  <p>Send us a message</p>
                  <h1>Don’t Hesitate To Contact Us <br/> Say Hello or Message</h1>
                </div>
              </div>
              <div className="col-12 col-lg-12">
                <div className="contact-form">                                                        
                  <form action="" className="row conact-form" ref={form} onSubmit={handleSubmit(sendEmail)}>
                    <div className="col-md-6 col-12">
                      <div className="single-personal-info">
                        <label htmlFor="fname">Full Name</label>
                        <input type="text" id="fname" placeholder="Enter Name" {...register("name", { required: true })} />                                         
                      </div>
                    </div>                            
                    <div className="col-md-6 col-12">
                      <div className="single-personal-info">
                        <label htmlFor="company_name">Company Name</label>
                        <input type="text" id="company_name" placeholder="Enter Company Name" {...register("company_name", { required: true })} />                                         
                      </div>
                    </div>                            
                    <div className="col-md-6 col-12">
                      <div className="single-personal-info">
                        <label htmlFor="email">Email Address</label>
                        <input type="email" id="email" placeholder="Enter Email Address" {...register("email", { required: true })} />                                         
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="single-personal-info">
                        <label htmlFor="phone">Phone Number</label>
                        <input type="text" id="phone" placeholder="Enter Number" {...register("phone_number", { required: true })} />                                         
                      </div>
                    </div>                                      
                    <div className="col-md-6 col-12">
                      <div className="single-personal-info">
                        <label htmlFor="county">County</label>
                        <input type="text" id="county" placeholder="Enter County" {...register("country", { required: true })} />                                         
                      </div>
                      
                    </div>                                      
                    <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                        <label htmlFor="document">Attachment</label>
                        <input type="file" id="document" name="document" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={handleAttachmentChange} />
                      </div>
                    </div>                                      
                    <div className="col-md-12 col-12">
                      <div className="single-personal-info">
                        <label htmlFor="description">Description</label>
                        <textarea id="description" placeholder="Enter Message" {...register("description")}></textarea>
                      </div>
                    </div>
                    <div className="col-md-12 col-12 text-center">
                      <button type="submit" className="theme-btn">Send Message <i className="fas fa-arrow-right"></i></button>
                    </div>                                      
                  </form>
                </div>
              </div>
            </div>
          </div>

      <FooterForCareer />
      <ToastContainer />

    </>
  );
};
