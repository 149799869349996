// import React from 'react'
// import soluptons from "../../../assets/img/techex-landing-page/we-provide-solutopns-bg.png"
// import { Row,Col} from 'react-bootstrap'

// export const Itstaffingimagesection = () => {
//   return (
//     <Row className="m-5">
//     <Row >
        
//         <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
//                     <a href="" className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
//                         {/* <div className="number">
//                             01
//                         </div> */}
//                         <div className="content card-image-mobile-service"> 
//                             <h3>Recruitment Process Outsourcing (RPO)</h3>
//                             <hr/>
//                             <p>A comprehensive service offering complete ownership for long-term efficiencies  and scalability for any Organization.</p>
//                         </div>
//                     </a>
//                 </div>
       
      
//         <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
//                     <a href="" className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
//                         {/* <div className="number">
//                             02
//                         </div> */}
//                         <div className="content"> 
//                             <h3>Professional Staffing and Services</h3>
//                             <hr/>
//                             <p>Tailored staffing solutions to hire specialized talent on-demand.Ensure seamless integration of specialized talent into your projects.</p>
//                         </div>
//                     </a>
//                 </div>
     
        
//                 <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
//                     <a href="" className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
//                         {/* <div className="number">
//                             03
//                         </div> */}
//                         <div className="content"> 
//                             <h3>Diversity, Inclusion and Innovation in Tech
// </h3>
// <hr/>
//                             <p>Facilitating organizations in building inclusive workplaces with diverse talent.We prioritize talents that benefits your company.</p>
//                         </div>
//                     </a>
//                 </div>
                
        
//     </Row>
//     <Row>
    
//     <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
//                     <a href="" className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
//                         {/* <div className="number">
//                             04
//                         </div> */}
//                         <div className="content"> 
//                             <h3>Research and <br />Consulting</h3>
//                             <hr/>
//                             <p>Insights and guidance on global market and talent strategies for growth and expansion.</p>
//                         </div>
//                     </a>
//                 </div>
                   
//     <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
//                     <a href="" className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
//                         {/* <div className="number">
//                             05
//                         </div> */}
//                         <div className="content"> 
//                             <h3>Project-Based <br />Consulting</h3>
//                             <hr/>
//                             <p>Utilize our seasoned consultants for end-to-end strategic IT project support, spanning planning, implementation.</p>
//                         </div>
//                     </a>
//                 </div>  
                   
//     <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
//                     <a href="" className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
//                         {/* <div className="number">
//                             06
//                         </div> */}
//                         <div className="content card-image"> 
//                             <h3>Expert Technical Assessments</h3>
//                             <hr/>
//                             <p>we provide thorough technical assessments to identify potential areas of improvement.</p>
//                         </div>
//                     </a>
//                 </div>
      
        
//     </Row>

// </Row>
//   )
// }




import React from 'react'
import soluptons from "../../../assets/img/techex-landing-page/we-provide-solutopns-bg.png"


import { Row, Col } from 'react-bootstrap'

const Itstaffingimagesection = () => {
    return (
        <Row className="top-margin">
<div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(    ${soluptons})` }}>
 <div className="content common-card-images">
                            <h3>Recruitment Process Outsourcing (RPO)</h3>
                            <hr />
                            <p>A comprehensive service offering complete ownership for long-term efficiencies  and scalability for any Organization.</p>
                        </div>
                    </a>
                </div>
                <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>

                        <div className="content common-card-images">
                            <h3>Professional Staffing and Services</h3>
                            <hr />
                            <p>
                            Tailored staffing solutions to hire specialized talent on-demand.Ensure seamless integration of specialized talent into your projects.
                            </p>
                        </div>
                    </a>
                </div>
                <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                        <div className="content card-image common-card-images">
                            <h3>Diversity, Inclusion and Innovation in Tech</h3>
                            <hr />
                            <p>Facilitating organizations in building inclusive workplaces with diverse talent.We prioritize talents that benefits your company.</p>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                        <div className="content common-card-images">
                            <h3>Project-Based <br />Consulting</h3>
                            <hr />
                            <p>Utilize our seasoned consultants for end-to-end strategic IT project support, spanning planning, implementation.</p>
                        </div>
                    </a>
                </div>
                <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                        <div className="content card-image common-card-images">
                            <h3>Maintenance & <br />Support </h3>
                            <hr />
                            <p>Build a well-constructed website and web application  functionality & interactivity</p>
                        </div>
                    </a>
                </div>
                <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                    <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>

                        <div className="content common-card-images">
                            <h3>Expert Technical Assessments</h3>
                            <hr />
                            <p>we provide thorough technical assessments to identify potential areas of improvement.</p>
                        </div>
                    </a>
                </div>

        </Row>
    )
}

export default Itstaffingimagesection