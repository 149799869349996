import React from 'react';
import { Row, Col } from 'react-bootstrap';
import paraimage from "../../../assets/img/IT-Satffing_new-1_.webp";
import { LuDot } from "react-icons/lu";


const Itstaffingfirstsection = () => {
  return (
    <div className='web-text'>
      <Row className="justify-content-center">
        <Col md={12} className="mb-4">
          <p style={{ textAlign: 'justify', lineHeight: '2.2' }}>

            Your premier destination for IT staffing and support services. We recognize the pivotal role skilled IT professionals play in the success of your business, which is why we are committed to providing top-notch staffing solutions and unparalleled support to meet your organization's needs.
          </p>
        </Col>
        <Col md={6} className='web_page_img_div'>
          <img
            src={paraimage}
            alt='it staffing and service'
            className="custom-image-webservice"
            style={{ borderRadius: '10px' }}
          />
        </Col>
        <Col md={6} className='d-flex align-items-center'>
          <ul className='icon-list'>
            <li className="icon-list-item">
              <LuDot className="icon" />
              <span style={{ marginLeft: '10px' }}>Customised staffing solutions to match your unique IT requirements, ensuring the right professionals for your projects.</span>
            </li>
            <li className="icon-list-item">
              <LuDot className="icon" />
              <span style={{ marginLeft: '10px' }}>Agile staffing solutions for swift adaptation to project changes, ensuring timely delivery and seamless support.</span>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default Itstaffingfirstsection;